import React from 'react'
import ReactDOM from 'react-dom'

import FrequentlyAskedQuestion from '../components/frequently_asked_question';

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frequentlyAskedQuestions: [],
    };
  }
  componentDidMount() {
    this.loadFrequentlyAskedQuestions();
  }

  loadFrequentlyAskedQuestions = () => {
    $.getJSON('/frequently_asked_questions', (frequentlyAskedQuestions) =>  {
      this.setState({ frequentlyAskedQuestions });
    });
  }

  render() {
    const { frequentlyAskedQuestions } = this.state;
    return (
      <div className='component-faq'>
        <h2>Frequently Asked Questions</h2>
        {
          frequentlyAskedQuestions.map((frequentlyAskedQuestion) => 
            <FrequentlyAskedQuestion 
              key={frequentlyAskedQuestion.id}
              frequentlyAskedQuestion={frequentlyAskedQuestion} />)
        }
      </div>
    );
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const mountPointElement = document.getElementById('faq-mount-point');

  if(mountPointElement) {
    ReactDOM.render(
      <Faq />,
      mountPointElement,
    )
  }
})
