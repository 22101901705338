import React from 'react';

class FrequentlyAskedQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  toggle = (e) => {
    e.preventDefault();
    const { collapsed } = this.state;
    this.setState({ collapsed : !collapsed });
  }

  render() {
    const { frequentlyAskedQuestion } = this.props;
    const { collapsed } = this.state;

    return(
      <div className='component-frequently-asked-question'>
        <a href='#' className='question' onClick={this.toggle}>
          <i className={`fa ${collapsed ? 'fa-chevron-right' : 'fa-chevron-down'}`}></i> {frequentlyAskedQuestion.question}
        </a>
        {
          !collapsed &&
          <div className='answer'>
            {frequentlyAskedQuestion.answer}
          </div>
        }
      </div>
    );
  }
}

export default FrequentlyAskedQuestion;